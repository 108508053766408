<template>
  <div class="w-1/2 mb-4 ml-2">
    <div class="flex ml-3">
      <div
        class="
          flex flex-auto
          bg-grey-light
          rounded
          overflow-auto
          text-grey-darkest
          group
          hover:bg-grey
        "
      >
        <font-awesome-icon
          @click="$refs.search.focus()"
          class="w-3 my-auto mx-1"
          :icon="['fas', 'search']"
        ></font-awesome-icon>
        <input
          ref="search"
          type="text"
          v-model="filter.search"
          style="outline: none"
          class="
            py-2
            pr-2
            w-full
            bg-grey-light
            group-hover:bg-grey
            whitespace-no-wrap
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'CityFilter',

  data () {
    return {
      filter: {
        search: this.$route.query['search'] || '',
      },
    }
  },
  created () {
    this.addFilters(this.filter)
  },
  watch: {
    filter: {
      handler (val) {
        this.addFilters(val)
      },
      deep: true,
    },
  },
  methods: {
    addFilters: debounce(function (filter) {
      let filterData = {}
      for (let key in filter) {
        if (filter[key] === '') {
          continue
        } else filterData[key] = filter[key]
      }
      this.$router.push({ query: filterData })
    }, 500),
    clear () {
      for (let key in this.filter) {
        this.filter[key] = ''
      }
    },
  },
}
</script>

<style scoped>
@tailwind utilities;
</style>
