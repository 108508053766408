<template>
  <div>
      <div
              class="checkbox-container"
              v-for="(product, index) in products"
              :key="index"
      >
          <div class="flex items-center">
              <input
                      type="checkbox"
                      v-model="product.isChecked"
              />
              <label class="checkmark ml-2">{{ product.name }}</label>
              <div v-if="product.isChecked" @click="product.isOpen = !product.isOpen"
                   class="text-grey-darker text-sm font-bold flex cursor-pointer ml-2" style="width: fit-content">
                  <font-awesome-icon v-if="product.isOpen"
                                     class="text-xl"
                                     :icon="['fal', 'angle-up']">
                  </font-awesome-icon>
                  <font-awesome-icon v-if="!product.isOpen"
                                     class="text-xl"
                                     :icon="['fal', 'angle-down']"></font-awesome-icon>
              </div>
          </div>
          <div v-if="product.isOpen && product.isChecked">
              <div class="my-4">
                  <label class="block text-grey-darker text-sm font-bold mb-2">Минимальная цена за билеты продуктов</label>
                  <input class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                         placeholder="Минимальная цена за билеты продуктов"
                        :value="product.ticket_minimum_cost"
                         @change="product.ticket_minimum_cost = Number($event.target.value)"
                         type="number"
                  >
                  <transition name="fade">
                         <span class="mt-2 text-red text-xs italic" v-if="errors.hasOwnProperty(`cities.${cityIndex}.products.${index}.ticket_minimum_cost`)">
                          {{errors[`cities.${cityIndex}.products.${index}.ticket_minimum_cost`][0] }}
                         </span>
                  </transition>
              </div>
              <div class="my-4">
                  <label class="block text-grey-darker text-sm font-bold mb-2">Стоимость продуктов за пакет</label>
                  <input class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                         placeholder="Минимальная цена за билеты продуктов"
                         :value="product.single_payment_cost"
                         @change="product.single_payment_cost = Number($event.target.value)"
                         type="number"
                  >
                  <transition name="fade">
                         <span class="mt-2 text-red text-xs italic" v-if="errors.hasOwnProperty(`cities.${cityIndex}.products.${index}.single_payment_cost`)">
                          {{errors[`cities.${cityIndex}.products.${index}.single_payment_cost`][0] }}
                         </span>
                  </transition>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PartnerProducts',
  props: ['cityProducts', 'errors', 'cityIndex'],
  data () {
    return {
      products: [],
    }
  },
  created () {
    axios.get('/api/products').then(({ data: response }) => {
      const data = response.data
      if (data) {
        data.forEach(item => {
          const foundIndex = this.cityProducts.findIndex(prod => {
            return prod.id === item.id
          })
          const singlePaymentCost = foundIndex !== -1 ? this.cityProducts[foundIndex].single_payment_cost : null
          const ticketMinimumCost = foundIndex !== -1 ? this.cityProducts[foundIndex].ticket_minimum_cost : null
          this.products.push({
            id: item.id,
            name: item.name,
            isOpen: false,
            single_payment_cost: singlePaymentCost,
            ticket_minimum_cost: ticketMinimumCost,
            isChecked: foundIndex !== -1,
          })
        })
      }
    })
  },
  computed: {
    onlyCheckedProducts () {
      return this.products.filter(item => item.isChecked)
    },
  },
  watch: {
    products: {
      handler () {
        this.$emit('updateCityProducts', this.onlyCheckedProducts)
      },
      deep: true,
    },
  },
}
</script>
