<template>
  <div>
    <div v-if="processing">
      <Spinner />
    </div>
    <div v-if="!processing">
      <div class="mb-4">
        <label class="block text-grey-darker text-sm font-bold mb-2" for="name">
          ФИО партнера
        </label>
        <input
          class="
            shadow
            appearance-none
            border border-grey
            w-full
            py-2
            px-3
            text-grey-darker
          "
          id="name"
          placeholder="Иванов Иван Иванович"
          v-model="form.name"
        />
        <transition name="fade">
          <span class="mt-2 text-red text-xs italic" v-if="wrongName">{{
            wrongName
          }}</span>
        </transition>
      </div>
      <div class="mb-4">
        <label
          class="block text-grey-darker text-sm font-bold mb-2"
          for="email"
        >
          Email
        </label>
        <input
          class="
            shadow
            appearance-none
            border border-grey
            w-full
            py-2
            px-3
            text-grey-darker
          "
          id="email"
          type="email"
          placeholder="name@mozgo.team"
          v-model="form.email"
        />
        <transition name="fade">
          <span class="mt-2 text-red text-xs italic" v-if="wrongEmail">{{
            wrongEmail
          }}</span>
        </transition>
      </div>

      <div class="flex flex-col-reverse">
        <div class="flex items-center justify-start" @click="addNewCity()">
          <div
            v-if="buttonText === 'Добавить город'"
            style="width: 40px; height: 40px"
            class="
              cursor-pointer
              border border-greay-darker
              rounded-full
              flex
              items-center
              justify-center
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 p-1 my-auto"
              viewBox="0 0 17.36 18.02"
            >
              <g data-name="Слой 2">
                <path
                  fill="#606f7b"
                  d="M7 18v-7.35H0V7.37h7V0h3.4v7.37h7v3.28h-7V18z"
                  data-name="Слой 1"
                />
              </g>
            </svg>
          </div>
          <span
            class="block text-grey-darker text-sm font-bold ml-2 cursor-pointer"
            >{{ setBtnText }}</span
          >
        </div>

        <div v-if="showCities" class="mb-4">
          <div v-for="(item, idx) in form.cities" :key="idx" class="my-4">
            <div>
              <label
                class="block text-grey-darker text-sm font-bold mb-2"
              >
                Город партнера и доступные продукты
              </label>
              <div class="flex items-center">
                <select
                  class="
                    shadow
                    appearance-none
                    border border-grey
                    w-full
                    py-2
                    px-3
                    text-grey-darker
                  "
                  v-model="item.id"
                >
                  <option
                    v-for="city in cities"
                    v-text="city.name"
                    :value="city.id"
                    :key="city.id"
                    class="bg-brand rounded p-2 text-white hover:bg-brand-dark"
                  ></option>
                </select>
                <div class="ml-4 cursor-pointer" @click="removeItem(idx, item)">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="times"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 352 512"
                    class="
                      text-grey
                      hover:text-grey-darkest
                      svg-inline--fa
                      fa-times fa-w-11
                    "
                  >
                    <path
                      fill="currentColor"
                      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                      class=""
                    ></path>
                  </svg>
                </div>
              </div>
                <transition name="fade">
               <span class="mt-2 text-red text-xs italic" v-if="errors.hasOwnProperty(`cities.${idx}.id`)">
                   {{errors[`cities.${idx}.id`][0] }}
               </span>
                </transition>
              <div class="my-4">
                <label
                    class="block text-grey-darker text-sm font-bold mb-2"
                    for="partner_name"
                >
                  Наименование партнера (район)
                </label>
                <input
                    class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                    id="partner_name"
                    v-model="item.partner_name"
                />
              </div>
              <div class="mb-4">
                <label
                    class="block text-grey-darker text-sm font-bold mb-2"
                    for="partner_social_network"
                >
                  Ссылка на соцсеть
                </label>
                <input
                    class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                    id="partner_social_network"
                    v-model="item.partner_social_network"
                />
              </div>
              <div class="my-4">
                    <label class="text-grey-darker text-sm font-bold mt-2">Тип партнёрства</label>
                    <div class="w-full mt-1">
                        <select v-model="item.partnership_type" class="p-1 border-solid border-brand-light border w-full rounded">
                            <option :value="null" class="bg-brand rounded text-white hover:bg-brand-dark">--Не выбрано--</option>
                            <option value="franchise" class="bg-brand rounded text-white hover:bg-brand-dark">Франшиза</option>
                            <option value="filial" class="bg-brand rounded text-white hover:bg-brand-dark">Филиал</option>
                        </select>
                    </div>
                  <transition name="fade">
                   <span class="mt-2 text-red text-xs italic" v-if="errors.hasOwnProperty(`cities.${idx}.partnership_type`)">
                    {{errors[`cities.${idx}.partnership_type`][0] }}
                   </span>
                  </transition>
              </div>
              <div class="mb-4">
                <label
                    class="block text-grey-darker text-sm font-bold mb-2"
                    for="partner_comment"
                >
                  Комментарий (к блокировке)
                </label>
                <input
                    class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                    id="partner_comment"
                    v-model="item.partner_comment"
                />
              </div>
              <div class="p-2 mb-4">
                <PartnerProducts :cityIndex="idx" :errors="errors" :cityProducts="item.products" @updateCityProducts="item.products = $event"/>
                  <transition name="fade">
                   <span class="mt-2 text-red text-xs italic" v-if="errors.hasOwnProperty(`cities.${idx}.products`)">
                    {{errors[`cities.${idx}.products`][0] }}
                   </span>
                  </transition>
              </div>
              <legalRecords :cityIndex="idx"
                            :email="form.email"
                            :errors="errors"
                            :records="item.partner_legal_records"
                            @updateLegalInfo="updateLegalInfo($event, idx)"/>
            </div>
            <div class="border-b mb-4"></div>
          </div>
          <transition name="fade">
            <span class="mt-2 text-red text-xs italic" v-if="wrongCities">{{
              wrongCities
            }}</span>
          </transition>
          <transition name="fade">
            <span class="mt-2 text-red text-xs italic" v-if="wrongProducts">{{
              wrongProducts
            }}</span>
          </transition>
          <transition name="fade">
            <span class="mt-2 text-red text-xs italic" v-if="duplicates">{{
              duplicates
            }}</span>
          </transition>
        </div>
      </div>
      <div class="mt-2">
        <button
          href="#"
          type="submit"
          @click="save"
          class="
            bg-brand
            transition
            hover:bg-brand-darker
            text-white
            rounded
            py-2
            px-4
          "
        >
          Подтвердить
        </button>

        <a
          href=""
          class="
            ml-2
            no-underline
            uppercase
            tracking-wide
            font-medium
            text-grey text-sm
          "
          @click.prevent="cancel"
        >
          Отменить
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MultiSelect from './MultiSelect'
import CityCheckbox from './checkbox'
import SearchSelect from '../../components/SearchSelect.vue'
import PackAndCategory from './PackAndCategory'
import ArrElement from './ArrElement.vue'
import Spinner from '../../components/spinner.vue'
import legalRecords from './legalRecords.vue'
import PartnerProducts from './PartnerProducts.vue'

export default {
  name: 'CityCreate',
  components: {
    PackAndCategory,
    CityCheckbox,
    MultiSelect,
    SearchSelect,
    ArrElement,
    Spinner,
    legalRecords,
    PartnerProducts,
  },
  props: ['partnerId', 'partnerInfo'],
  data () {
    return {
      buttonText: '',
      wrongName: '',
      wrongEmail: '',
      wrongCities: '',
      wrongProducts: '',
      duplicates: '',
      requestSuccess: false,
      processing: false,
      checkedProducts: [],
      form: {
        email: '',
        name: '',
        cities: [],
      },
      showCities: false,
      errors: { },
    }
  },
  created () {
    if (this.partnerId !== undefined) {
      this.form = {
        email: this.partnerInfo.email,
        name: this.partnerInfo.name,
        cities: this.partnerInfo.cities.map((element) => {
          return {
            id: element.city_id,
            products: element.products.map((el) => {
              return {
                id: el.id,
                name: el.name,
                single_payment_cost: el.single_payment_cost,
                ticket_minimum_cost: el.ticket_minimum_cost,
              }
            }),
            partner_comment: element.comment,
            partner_name: element.name,
            partnership_type: element.partnership_type,
            partner_social_network: element.social_network,
            partner_legal_records: element.legal_record,
          }
        }),
      }
      setTimeout(() => this.addNewCity(), 1000)
    }

    if (this.partnerId === undefined) {
      this.showAndAddCities()
    }

    axios.get('/api/cities/all?sort=name,id').then(({ data: response }) => {
      this.cities = response.data
    })
  },
  methods: {
    addNewCity () {
      if (!this.showCities) {
        this.showAndAddCities()
      } else {
        this.addItemToArray()
      }
    },
    showAndAddCities () {
      this.showCities = true
    },
    addItemToArray () {
      this.form.cities.push({
        id: null,
        products: [],
      })
    },
    removeItem (idx) {
      this.form.cities.splice(idx, 1)
    },
    save () {
      let form = this.form.cities.map((item) => {
        return item.id
      })
      let checkDuplicate = form.some((item, idx) => {
        return form.indexOf(item) != idx
      })
      if (checkDuplicate) {
        this.duplicates =
          'Вы выбрали 2 одинаковых города. Удалите дубликат и повторите попытку.'
        return
      } else {
        this.duplicates = ''
      }
      if (this.partnerId === undefined) {
        axios
          .post(`api/partners`, this.form)
          .then(() => {
            this.removeErrorsText()
            this.stopSpinner()
          })
          .catch((error) => {
            this.errors = error.response.data.errors
            this.formValidation()
          })
      } else {
        axios
          .patch(`api/partners/${this.partnerId}`, this.form)
          .then(() => {
            this.removeErrorsText()
            this.stopSpinner()
          })
          .catch((error) => {
            this.errors = error.response.data.errors
            this.formValidation()
          })
      }
    },
    removeErrorsText () {
      this.wrongName = ''
      this.wrongEmail = ''
      this.wrongProducts = ''
      this.wrongCities = ''
      this.duplicates = ''
      this.errors = {}
    },
    stopSpinner () {
      this.processing = true
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    },
    formValidation () {
      if (this.errors.name) {
        this.wrongName = this.errors.name[0]
      } else {
        this.wrongName = ''
      }
      if (this.errors.email) {
        this.wrongEmail = this.errors.email[0]
      } else {
        this.wrongEmail = ''
      }

      if (
        Object.keys(this.errors).filter((obj) => {
          return obj.includes('cities') && !obj.includes('product')
        }).length > 0
      ) {
        if (this.errors.cities) {
          this.addNewCity()
        } else {
          this.wrongCities = ''
        }
        this.wrongCities = 'Ошибка при заполнении данных городов.'
      } else {
        this.wrongCities = ''
      }

      if (
        Object.keys(this.errors).filter((obj) => {
          return obj.includes('product')
        }).length > 0
      ) {
        this.wrongProducts = 'Ошибка при заполнении данных продуктов.'
      } else {
        this.wrongProducts = ''
      }
    },
    cancel () {
      this.modalHolder.close()
    },
    updateLegalInfo ($event, idx) {
      this.form.cities[idx].partner_legal_records = $event
    },
  },
  computed: {
    setBtnText () {
      return !this.showCities && this.partnerId !== undefined
        ? (this.buttonText = '')
        : (this.buttonText = 'Добавить город')
    },
  },
}
</script>

<style scoped>
@tailwind utilities;
</style>
