<script>
import clone from 'lodash/cloneDeep'

export default {
  name: 'QuestionsFilter',

  props: ['mainUrl'],

  created () {
    this.values = clone(this.$route.query)
  },

  data () {
    return {
      values: {},
    }
  },

  watch: {
    values () {
      this.$router.push({ query: this.values })
    },
  },

  render () {
    return this.$scopedSlots.default({
      link: this.apiLink,
      values: this.values,
    })
  },

  computed: {
    apiLink () {
      return this.mainUrl + this.filterParams
    },
    filterParams () {
      let response = []
      for (let key in this.$route.query) {
        if (key !== 'page') {
          if (key === 'city') {
            response.push(`filter[state][${this.$route.query.city_search_type}] = ${this.$route.query[key]}`)
            continue
          }
          if (key === 'city_search_type') {
            continue
          }
          if (key === 'game_type_name') {
            continue
          }
          if (key === 'start_date') {
            response.push(`filter[published_at][gte]=${this.$route.query['start_date']}`)
            continue
          }
          if (key === 'end_date') {
            response.push(`filter[published_at][lte]=${this.$route.query['end_date']}`)
            continue
          }
          response.push(`filter[${key}]=${this.$route.query[key]}`)
        } else {
          response.push(`page[number]=${this.$route.query[key]}`)
        }
      }
      return response.join('&')
    },
  },
}
</script>
