var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mx-auto flex flex-col px-8 mx-4"},[_c('partner-filter',{staticClass:"-mb-9 z-10"}),_c('div',{staticClass:"container mx-auto flex justify-between"},[_c('smart-filter',{attrs:{"main-url":'/api/partners/all?sort=name,-id&',"route":_vm.$route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var link = ref.link;
var values = ref.values;
return _c('div',{staticClass:"w-full"},[_c('pagination',{attrs:{"base-url":link},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var partners = ref.data;
var processing = ref.processing;
var switchPage = ref.switchPage;
var fetchData = ref.fetchData;
return _c('div',{staticClass:"w-full px-4"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"px-4 py-2"}),_c('button',{staticClass:"\n                  flex\n                  ml-auto\n                  text-white\n                  no-underline\n                  px-4\n                  py-2\n                  bg-brand\n                  rounded\n                  hover:bg-brand-light\n                  whitespace-no-wrap\n                ",on:{"click":function($event){$event.preventDefault();return _vm.createPartner($event)}}},[_vm._v("\n                Добавить партнера\n              ")])]),(!processing)?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex w-full justify-between items-baseline mb-8"},[_c('div'),_c('item-count',{attrs:{"title":'партнеров'}})],1),_c('div',{staticClass:"\n                  rounded\n                  border-grey\n                  shadow-md\n                  text-left\n                  w-full\n                "},[_c('table',{staticClass:"p-4 tableSize"},[_c('thead',[_c('tr',[_c('th',{staticClass:"sizeMin"}),_c('th',[_vm._v("Партнеры")]),_c('th',[_vm._v("Почта")]),_c('th',{staticClass:"citySize"},[_vm._v("Город")]),_c('th',{staticClass:"citySize"},[_vm._v("Доступные продукты")]),_c('th',{staticClass:"citySize"},[_vm._v("Статус")]),_c('th',{staticStyle:{"width":"60px"}},[_vm._v("Статус блок")]),_c('th',[_vm._v("Комментарий")])])]),_c('tbody',_vm._l((partners),function(partner,key){return _c('partners',{key:key,attrs:{"value":partner,"partner":partner}})}),1)])]),_c('pagination-links',{on:{"page":switchPage}})],1):_c('spinner'),_c('portal',{attrs:{"to":"create-new-partner"}},[_c('partner-create',{on:{"created":fetchData}})],1)],1)}}],null,true)})],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }