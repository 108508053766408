<template>
    <tr class="w-full hover:bg-grey-lightest my-1">
        <td>
            <div class="brand-checkbox my-auto mx-auto">
                <input class="" :checked="checked" type="checkbox" :id="`ch-${city.id}`"/>
                <label :for="`ch-${city.id}`"  @click="$emit('selectCity')"></label>
            </div>
        </td>
        <td v-text="city.id"></td>
        <td class="mr-auto" v-text="city.name"></td>
        <td v-text="city.email"></td>
        <td v-text="city.pack_numbers"></td>
        <!--<td v-text="city.includedRegions.name"></td>-->
        <td>
            <div class="my-auto mx-auto">
                <a href="" @click.prevent="editCity(city.id)">
                    <font-awesome-icon class="text-black text-base hover:bg-grey" :icon="['fas', 'edit']"></font-awesome-icon>
                </a>
            </div>
        </td>
        <td class="border-r-0 ">
            <a href="" class="text-grey-darkest no-underline hover:text-grey"
               @click.prevent="remove(city.id)">
                <trash></trash>
            </a>
            <portal :to="`edit-city-${city.id}`">
                <city-create :city-id="city.id" @created="$emit('updated')"></city-create>
            </portal>
        </td>
    </tr>
</template>

<script>
import Trash from '../../icons/trash'
import CityCreate from './create'

export default {
  components: { CityCreate, Trash },
  props: ['city', 'checked'],
  methods: {
    editCity (id) {
      this.modalHolder.open(`edit-city-${id}`, 'Изменение города', 'city-modal')
    },
    remove (id) {
      this.$emit('removeCity', [id])
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;

    td {
        @apply .text-left .p-2 .bg-white .font-sans .text-sm .border-b .border-r .border-grey-light;
    }
</style>
