<template>
    <div class="flex flex-col">
        <div class="pt-1 flex flex-col flex-1 mb-4">
            <label class="font-semibold text-brand mt-2 pb-1">Категория</label>
            <div class="w-full mt-auto">
                <search-select
                        v-model="pack.game_type_id"
                        :options="gameTypes"
                        :filterFunction="applySearchFilter">
                </search-select>
            </div>
        </div>
        <div>
            <label class="block text-grey-darker text-sm font-bold mb-2" for="pack_numbers">
                Номер последнего выданного пакета
            </label>
            <input class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                   id="pack_numbers"
                   type="text"
                   placeholder="Номера пакетов разделенных запятой и/или тире"
                   v-model="pack.ids"
                   @keypress="$emit('denySymbols')"
            >
            <!--<p v-if="errors.has('pack_numbers')"-->
               <!--v-text="errors.first('pack_numbers')"-->
               <!--class="mt-2 text-red text-xs italic"-->
            <!--&gt;</p>-->
        </div>
        <hr>
    </div>
</template>

<script>
import clone from 'lodash/cloneDeep'
import SearchSelect from '../question/SearchSelect'

export default {
  name: 'PackAndCategory',
  components: { SearchSelect },
  props: ['errors', 'packNumber', 'gameTypes'],
  data () {
    return {
      pack: {},
    }
  },
  created () {
    this.pack = clone(this.packNumber)
  },
  methods: {
    applySearchFilter (search, thems) {
      let tempArr = []
      thems.forEach((item) => {
        if (((item.name).toLowerCase()).indexOf(search.toLowerCase()) > -1) tempArr.push((item))
      })
      return tempArr
    },
  },
  watch: {
    pack: {
      handler (val) {
        this.$emit('changed', {
          game_type_id: val.game_type_id,
          ids: val.ids,
        })
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
