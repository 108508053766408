<template>
  <div>
      <div class="mb-4">
          <div @click="showLegalInformationFields =!showLegalInformationFields"
               class="text-grey-darker text-sm font-bold flex cursor-pointer" style="width: fit-content">
              <p class="mb-1 mr-2">Юридическая информация</p>
              <font-awesome-icon v-if="showLegalInformationFields"
                                 class="text-xl"
                                 :icon="['fal', 'angle-up']">
              </font-awesome-icon>
              <font-awesome-icon v-if="!showLegalInformationFields"
                                 class="text-xl"
                                 :icon="['fal', 'angle-down']"></font-awesome-icon>
          </div>

          <transition name="fade">
              <div v-if="showLegalInformationFields">
                  <div class="my-4" v-for="(item, index) in legalFields" :key="index">
                      <div v-if="['partner_contract_date', 'birth_date'].includes(item.value)">
                          <div v-if="item.value === 'partner_contract_date'" class="w-full mt-auto">
                              <label class="block text-grey-darker text-sm font-bold mb-2">{{item.name}}</label>
                              <v-date-picker
                                      v-model="form.partner_contract_date"
                                      class="flex-auto py-1.5 px-3 text-grey-darker border-solid border-brand-light border rounded shadow appearance-none"
                                      show-caps>
                              </v-date-picker>
                          </div>
                          <div v-if="item.value === 'birth_date'" class="w-full mt-auto">
                              <label class="block text-grey-darker text-sm font-bold mb-2">{{item.name}}</label>
                              <v-date-picker
                                      v-model="form.birth_date"
                                      class="flex-auto py-1.5 px-3 text-grey-darker border-solid border-brand-light border rounded shadow appearance-none"
                                      show-caps>
                              </v-date-picker>
                          </div>
                      </div>
                      <div v-else>
                          <label class="block text-grey-darker text-sm font-bold mb-2">{{item.name}}</label>
                          <input class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                                 :placeholder="item.name"
                                 :value="form[item.value]"
                                 @change="item.type === 'number'? form[item.value] = Number($event.target.value) : form[item.value] = $event.target.value"
                                 :type="item.type"
                                 @blur="isEmailWork(item.value)"
                          >
                      </div>
                      <transition name="fade">
                         <span class="mt-2 text-red text-xs italic" v-if="errors.hasOwnProperty(`cities.${cityIndex}.partner_legal_records.${item.value}`)">
                          {{errors[`cities.${cityIndex}.partner_legal_records.${item.value}`][0] }}
                         </span>
                      </transition>
                  </div>
              </div>
          </transition>
      </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerLegalRecords',
  props: ['records', 'errors', 'cityIndex', 'email'],
  data () {
    return {
      legalFields: [
        { name: 'Наименование Юр Лица в 1С ', value: '1s_legal_entity', type: 'text' },
        { name: 'Юридическое лицо', value: 'legal_entity', type: 'text' },
        { name: 'Абонентская плата', value: 'subscription_cost', type: 'number' },
        { name: 'Валюта абонентской платы', value: 'subscription_bill_currency', type: 'text' },
        { name: 'Процент роялти', value: 'royalty_percent', type: 'number' },
        { name: 'Валюта роялти', value: 'royalty_currency', type: 'text' },
        { name: 'Номер договора', value: 'partner_contract_name', type: 'text' },
        { name: 'Дата договора', value: 'partner_contract_date', type: 'text' },
        { name: 'Юр лицо УК', value: 'contract_management_company_legal_entity', type: 'text' },
        { name: 'Дата рождения', value: 'birth_date', type: 'text' },
        { name: 'Рабочий имейл 1с', value: 'email_work', type: 'text' },
        { name: 'Персональный имейл 1с', value: 'email_personal', type: 'text' },
      ],
      form: {
        '1s_legal_entity': null,
        legal_entity: null,
        subscription_cost: null,
        subscription_bill_currency: null,
        royalty_percent: null,
        royalty_currency: null,
        partner_contract_name: null,
        partner_contract_date: null,
        contract_management_company_legal_entity: null,
        birth_date: null,
        email_work: null,
        email_personal: null,
      },
      showLegalInformationFields: false,
    }
  },
  created () {
    for (const key in this.records) {
      if (key !== 'partner_contract_date' && key !== 'birth_date') {
        this.form[key] = this.records[key]
      } else {
        this.form[key] = new Date(this.records[key])
      }
    }
    if (this.email && !this.form.email_work) {
      this.form.email_work = this.email
    }
  },
  methods: {
    isEmailWork (value) {
      if (value === 'email_work' && !this.form.email_work) {
        this.form.email_work = this.email
      }
    },
  },
  watch: {
    'form': {
      handler () {
        this.$emit('updateLegalInfo', this.form)
      },
      deep: true,
    },
    email: {
      handler () {
        if (!this.form.email_work) {
          this.form.email_work = this.email
        }
      },
    },
  },
}
</script>
