<template>
    <div class="w-1/2 mb-4 px-4">
        <div class="flex">
            <a @click.prevent="clear" class="text-brand hover:bg-brand-light mb-2 mr-4">
                Сбросить все фильтры
            </a>
        </div>

        <div class="flex -mx-2">
            <div class="mx-2 flex">
                <label class="font-semibold text-brand my-auto">Играет в Квизмейкер</label>
                <city-checkbox class="mx-2 mt-2" id="is_playing_quizmaker" v-model="filter.is_playing_quizmaker"
                               :element-id="`city-filter`"></city-checkbox>
            </div>
            <div class="flex flex-auto bg-grey-light rounded text-grey-darkest group hover:bg-grey">
                <font-awesome-icon @click="$refs.search.focus()" class="w-3 my-auto mx-1"
                                   :icon="['fas', 'search']"></font-awesome-icon>
                <input ref="search" type="text" v-model="filter.name" style="outline:none"
                       class="py-2 bg-grey-light group-hover:bg-grey whitespace-no-wrap"/>
            </div>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import CityCheckbox from './checkbox'

export default {
  name: 'CityFilter',
  components: { CityCheckbox },
  data () {
    return {
      filter: {
        is_playing_quizmaker: this.$route.query['is_playing_quizmaker'] || true,
        name: this.$route.query['name'] || '',
      },
    }
  },
  created () {
    this.addFilters(this.filter)
  },
  watch: {
    filter: {
      handler (val) {
        this.addFilters(val)
      },
      deep: true,
    },
  },
  methods: {
    addFilters: debounce(function (filter) {
      let filterData = {}
      for (let key in filter) {
        if (filter[key] === '') {
          continue
        } else filterData[key] = filter[key]
      }
      this.$router.push({ query: filterData })
    }, 500),
    clear () {
      for (let key in this.filter) {
        this.filter[key] = ''
      }
    },
  },
}
</script>

<style scoped>
    @tailwind utilities;
</style>
