<template>
    <div class="flex justify-end mb-1">Всего {{title}}: {{total}}</div>
</template>

<script>
export default {
  name: 'ItemCount',
  inject: ['paginationState'],
  props: ['title'],
  computed: {
    total () {
      return this.paginationState.meta.total
    },
  },
}

</script>
