<template>
    <div class="container mx-auto flex flex-col px-8 mx-4">
        <city-filter class="-mb-9 z-10"></city-filter>
        <div class="container mx-auto flex justify-between">
            <smart-filter :main-url="'/api/cities?sort=name,id&'" :route="$route">
                <div class="w-full" slot-scope="{link, values}">
                    <pagination :base-url="link">
                        <div class="w-full px-4" slot-scope="{data: cities, processing,switchPage, fetchData}">
                            <button @click.prevent="createCity"
                                    class="flex ml-auto text-white no-underline px-4 py-2 bg-brand rounded hover:bg-brand-light whitespace-no-wrap">
                                Добавить город
                            </button>
                            <div class="w-full" v-if="!processing">
                                <item-count :title="'городов'"></item-count>
                                <div class="rounded border-grey shadow-md text-left w-full overflow-x-auto">
                                    <table class="w-full p-4">
                                        <thead>
                                        <tr>
                                            <th>
                                                <a href="" class="text-grey-darkest no-underline hover:text-grey mx-auto my-auto"
                                                   title="Выбрать всё на этой странице"
                                                   @click.prevent="selectAll(cities)">
                                                    <font-awesome-icon v-if="checked" class="w-6"
                                                                       :icon="['fas', 'angle-up']"></font-awesome-icon>
                                                    <font-awesome-icon v-else class="w-6"
                                                                       :icon="['fas', 'angle-down']"></font-awesome-icon>
                                                </a>
                                            </th>
                                            <th>№</th>
                                            <th class="mr-auto">Название</th>
                                            <th>Email</th>
                                            <th>Выданные пакеты</th>
                                            <th> </th>
                                            <th class="border-r-0 ">
                                                <a href="" class="text-grey-darkest no-underline hover:text-grey"
                                                   title="Удалить выбранные"
                                                   @click.prevent="remove(selectedCities, fetchData)">
                                                    <trash></trash>
                                                </a>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <city v-for="(city, key) in cities" :value="city" :key="key"
                                              :city="city"
                                              :checked="selectedCities.indexOf(city.id) !== -1"
                                              @removeCity="remove([city.id], fetchData)"
                                              @selectCity="selectCity(city.id)"
                                              @updated="fetchData"
                                        ></city>
                                        </tbody>
                                    </table>
                                </div>
                                <pagination-links @page="switchPage"></pagination-links>
                            </div>
                            <spinner v-else></spinner>
                            <portal to="create-new-city">
                                <city-create class="" @created="fetchData"></city-create>
                            </portal>
                        </div>
                    </pagination>
                </div>
            </smart-filter>
        </div>
    </div>
</template>

<script>

import Pagination from '../../components/pagination.vue'
import PaginationLinks from '../../components/pagination-links.vue'
import Trash from '../../icons/trash'
import map from 'lodash/map'
import axios from 'axios'
import swal from 'sweetalert2'
import ItemCount from '../../components/itemCount'
import City from './city'
import CityCreate from './create'
import Spinner from '../../components/spinner'
import SmartFilter from '../../components/questions/filter.vue'
import CityFilter from './filter-panel'

export default {
  components: {
    CityFilter,
    Spinner,
    CityCreate,
    City,
    ItemCount,
    Trash,
    PaginationLinks,
    Pagination,
    SmartFilter,
  },

  watch: {
    '$route' (to, from) {
      this.clearSelected()
    },
  },
  data () {
    return {
      selectedCities: [],
      checked: false,
    }
  },
  methods: {
    createCity () {
      this.modalHolder.open('create-new-city', 'Добавить город', 'city-modal')
    },
    selectAll (questions) {
      let ids = map(questions, 'id')
      if (this.selectedCities.length === ids.length) {
        this.clearSelected()
      } else {
        this.selectedCities = ids
        this.checked = true
      }
    },
    selectCity (id) {
      if (this.selectedCities.indexOf(id) !== -1) {
        this.dropSelected(this.selectedCities, id)
      } else {
        this.selectedCities.push(id)
      }
    },
    clearSelected () {
      this.selectedCities = []
      this.checked = false
    },
    dropSelected (array, id) {
      let index = array.indexOf(id)
      if (index !== -1) array.splice(index, 1)
    },
    remove (ids, fetchData) {
      if (ids.length === 0) {
        swal('Выберите город!', 'Необходимо выбрать элементы для удаления', 'error')
        return
      }
      let message
      let link
      ids = ids.join('&ids[]=')
      link = `/api/cities?ids[]=${ids}`
      message = 'Город будет удален, это действие невозможно отменить!'
      this.confirmDelete(message).then((result) => {
        axios.delete(link).then(() => {
          swal('Удалено!', 'Город удален.', 'success')
          fetchData()
          this.clearSelected()
        })
      })
    },

    confirmDelete (message) {
      return new Promise(function (resolve) {
        swal({
          title: 'Вы уверены?',
          text: message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Да удалить!',
          cancelButtonText: 'Отмена',
        }).then((result) => {
          if (result.value) {
            resolve(result)
          }
        })
      })
    },
  },
}
</script>

<style scoped>
    @tailwind utilities;

    th {
        @apply text-left
        p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
    }
</style>
