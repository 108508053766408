<script>
import clone from 'lodash/cloneDeep'
import axios from 'axios'

export default {
  name: 'Pagination',

  provide () {
    return {
      paginationState: this.sharedState,
    }
  },

  props: {
    baseUrl: {
      type: String,
    },
  },
  data () {
    return {
      data: [],
      sharedState: {
        meta: {},
      },
      currentUrl: this.baseUrl,
      processing: false,
    }
  },

  created () {
    this.fetchData()
  },

  watch: {
    baseUrl () {
      this.currentUrl = this.baseUrl
      this.fetchData()
    },
  },

  methods: {
    fetchData () {
      this.processing = true
      axios.get(this.currentUrl).then(({ data }) => {
        this.data = data.data
        this.sharedState.meta = data.meta
        this.processing = false
      })
    },
    switchPage (page) {
      let query = clone(this.$route.query)
      query['page'] = page

      this.$router.push({ query })
    },
  },
  render () {
    return this.$scopedSlots.default({
      data: this.data,
      meta: this.meta,
      processing: this.processing,
      switchPage: this.switchPage,
      fetchData: this.fetchData,
    })
  },
}

</script>
