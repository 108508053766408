<template>
    <form @submit.prevent="save" action="/api/cities" v-if="loaded">
        <div class="">
            <div class="mb-4">
                <label class="block text-grey-darker text-sm font-bold mb-2" for="name">
                    Название города
                </label>
                <input class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                       :class="{'border-red': form.errors.has('name')}"
                       id="name"
                       placeholder="Название города"
                       v-model="form.name"
                >
                <p v-if="form.errors.has('name')"
                   v-text="form.errors.first('name')"
                   class="mt-2 text-red text-xs italic"
                ></p>
            </div>
            <div class="my-2">
                       <label class="text-grey-darker text-sm font-bold mb-2">Чиленность населения</label>
            <div class="w-full">
                <select v-model="form.population" class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker">
                    <option value="До 50 тысяч" class="bg-brand rounded text-white hover:bg-brand-dark">До 50 тысяч</option>
                    <option value="От 50 до 100 тысяч" class="bg-brand rounded text-white hover:bg-brand-dark">От 50 до 100 тысяч</option>
                    <option value="От 100 тысяч до 500 тысяч" class="bg-brand rounded text-white hover:bg-brand-dark">От 100 тысяч до 500 тысяч</option>
                    <option value="От 500 тысяч до 1 миллиона" class="bg-brand rounded text-white hover:bg-brand-dark">От 500 тысяч до миллиона</option>
                    <option value="Более 1 миллиона" class="bg-brand rounded text-white hover:bg-brand-dark">Более миллиона</option>
                </select>
                </div>
            </div>

<!--            <div class="mb-4">-->
<!--                <label class="block text-grey-darker text-sm font-bold mb-2" for="email">-->
<!--                    Email-->
<!--                </label>-->
<!--                <input class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"-->
<!--                       :class="{'border-red': form.errors.has('email')}"-->
<!--                       id="email"-->
<!--                       type="email"-->
<!--                       placeholder="name@mozgo.team"-->
<!--                       v-model="form.email"-->
<!--                >-->
<!--                <p v-if="form.errors.has('email')"-->
<!--                   v-text="form.errors.first('email')"-->
<!--                   class="mt-2 text-red text-xs italic"-->
<!--                ></p>-->
<!--            </div>-->

            <div class="mb-4 flex">
                <label class="block text-grey-darker text-sm font-bold mb-2">
                    Играет в Квизмейкер
                </label>
                <city-checkbox class="mx-2" v-model="form.is_playing_quizmaker"
                               :element-id="`city-${cityId}`"></city-checkbox>
            </div>

            <div class="mb-4">
                <label class="block text-grey-darker text-sm font-bold mb-2" for="region">
                    Регион
                </label>
                <multi-select v-model="form.region_ids" :options="includedRegions"></multi-select>

                <div class="flex flex-row pt-4">
                    <div class="flex flex-row" v-for="region in form.region_ids">
                        <p class="p-2 mr-4 bg-brand rounded-lg text-white" v-text="region.name"></p>
                    </div>
                </div>
            </div>

            <div v-if="templates" class="mb-4">
                <label class="block text-grey-darker text-sm font-bold mb-2" for="comment">
                    Страна
                </label>
                <select class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                        :class="{'border-red': form.errors.has('country_id')}"
                        id="country_id"
                        v-model="form.country_id">
                    <option v-for="country in countries" v-text="country.name" :value="country.id" class="bg-brand rounded p-2 text-white hover:bg-brand-dark"></option>
                </select>
                <p v-if="form.errors.has('country_id')"
                   v-text="form.errors.first('country_id')"
                   class="mt-2 text-red text-xs italic"
                ></p>
            </div>
            <div class="mb-4">
                <label class="block text-grey-darker rext-sm font-bold mb-2" for="comment">
                    Часовой пояс
                </label>

                <search-select
                        v-model="form.timezone"
                        :options="timezones"
                        :filterFunction="applySearchFilter">
                </search-select>
            </div>
            <div v-if="templates" class="mb-4">
                <label class="block text-grey-darker text-sm font-bold mb-2" for="comment">
                    Шаблон
                </label>
                <select class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                        :class="{'border-red': form.errors.has('template_id')}"
                        id="template_id"
                        v-model="form.template_id">
                    <option value="" class="bg-brand rounded text-white p-2 hover:bg-brand-dark">Не выбрано</option>
                    <option v-for="template in templates" v-text="template.name" :value="template.id" class="bg-brand rounded p-2 text-white hover:bg-brand-dark"></option>
                </select>
                <p v-if="form.errors.has('template_id')"
                   v-text="form.errors.first('template_id')"
                   class="mt-2 text-red text-xs italic"
                ></p>
            </div>

            <div class="mb-4">
                <label class="block text-grey-darker text-sm font-bold mb-2" for="social_network_link">
                    Ссылка на социальную сеть
                </label>
                <input class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                       :class="{'border-red': form.errors.has('social_network_link')}"
                       id="social_network_link"
                       placeholder="Введите ссылку https://..."
                       v-model="form.social_network_link"
                >
                <p v-if="form.errors.has('social_network_link')"
                   v-text="form.errors.first('social_network_link')"
                   class="mt-2 text-red text-xs italic"
                ></p>
            </div>
            <div class="mb-4">
                <label class="block text-grey-darker text-sm font-bold mb-2" for="comment">
                    Комментарий
                </label>
                <textarea class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                          :class="{'border-red': form.errors.has('comment')}"
                          id="comment"
                          rows="2"
                          placeholder="Введите комментарий"
                          v-model="form.comment">
            </textarea>
                <p v-if="form.errors.has('comment')"
                   v-text="form.errors.first('comment')"
                   class="mt-2 text-red text-xs italic"
                ></p>
            </div>

            <div class="mb-4" v-if="gameTypes.length > 0">
                <label class="block text-grey-darker text-sm font-bold mb-2">Номер пакетов
                </label>
                <pack-and-category v-for="(pack_number, key) in form.pack_numbers" :key="key"
                                   :errors="form.errors" :pack-number="pack_number"
                                   :game-types="gameTypes"
                                   @changed="changePackNumber(key, $event)"
                                   @denySymbols="denySymbols()" ></pack-and-category>
                <div v-if="gameTypes.length !== form.pack_numbers.length" @click="addNewPack" class="cursor-pointer">
                    <font-awesome-icon class="text-black text-base" :icon="['fas', 'plus']"></font-awesome-icon>
                    Добавить исключения
                </div>
            </div>

            <div class="mb-4">
                <label class="block text-grey-darker text-sm font-bold mb-2">Территория</label>
                <input class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                       placeholder="Территория"
                       v-model="form.territory"
                >
            </div>

            <div>
                <label class="block text-grey-darker text-sm font-bold mb-2">Область</label>
                <input class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                       placeholder="Область"
                       v-model="form.sub_region"
                >
            </div>

            <div class="my-4">
                <label class="block text-grey-darker text-sm font-bold mb-2">Фактическое население</label>
                <input class="shadow appearance-none border border-grey w-full py-2 px-3 text-grey-darker"
                       placeholder="Фактическое население"
                       v-model="form.fact_population"
                       type="number"
                >
            </div>

            <div class="mb-4 flex">
                <input class="" v-model="form.is_show_in_popup" type="checkbox" id="is_show_in_popup"/>
                <label class="text-grey-darker text-sm font-bold ml-2">Показывать игрокам</label>
            </div>

            <div class="mt-2">
                <button href="#"
                        type="submit"
                        class="bg-brand transition  hover:bg-brand-darker text-white rounded py-2 px-4"
                >
                    Добавить город
                </button>

                <a href=""
                   class="ml-2 no-underline uppercase tracking-wide font-medium text-grey text-sm"
                   @click.prevent="cancel"
                >
                    Отменить
                </a>
            </div>
        </div>
    </form>
</template>

<script>
import Form from 'form-backend-validation'
import axios from 'axios'
import MultiSelect from './MultiSelect'
import CityCheckbox from './checkbox'
import SearchSelect from '../../components/SearchSelect.vue'
import PackAndCategory from './PackAndCategory'

export default {
  name: 'CityCreate',
  components: { PackAndCategory, CityCheckbox, MultiSelect, SearchSelect },
  props: {
    cityId: Number,
  },
  data () {
    return {
      form: false,
      regions: [],
      includedRegions: [],
      templates: [],
      gameTypes: [],
      countries: [],
      is_playing_quizmaker: false,
      loaded: false,
      timezones: [],
    }
  },
  created () {
    axios.get('/api/regions')
      .then(({ data: response }) => {
        this.includedRegions = response
      })

    axios.get('/api/regular-templates?filter[game_type_id]=1')
      .then(({ data: response }) => {
        this.templates = response.data
      })

    axios.get('/api/game-types/old-packs')
      .then(({ data: response }) => {
        this.gameTypes = response
      })

    axios.get('api/timezones')
      .then(({ data: response }) => {
        let tz = response
        tz.forEach(timezone => this.timezones.push({ 'name': timezone.timezone, 'id': timezone.timezone }))
      })

    axios.get('/api/countries')
      .then(({ data: response }) => {
        this.countries = response

        this.form = new Form({
          name: '',
          comment: '',
          pack_numbers: [],
          region_ids: [],
          template_id: '',
          country_id: this.defaultCountry.id,
          social_network_link: '',
          is_playing_quizmaker: false,
          timezone: '',
          population: '',
          territory: '',
          sub_region: '',
          fact_population: '',
          is_show_in_popup: false,
        }, { http: axios })

        if (this.cityId !== undefined) {
          axios.get(`/api/cities/${this.cityId}`)
            .then(({ data }) => {
                console.log(data)
              this.form = new Form({
                name: data.name || '',
                comment: data.comment || '',
                pack_numbers: data.pack_numbers || [],
                region_ids: data.regions,
                template_id: data.template_id || '',
                country_id: data.country_id || this.defaultCountry.id,
                social_network_link: data.social_network_link || '',
                is_playing_quizmaker: data.is_playing_quizmaker || false,
                timezone: data.timezone || '',
                population: data.population || '',
                territory: data.territory || '',
                sub_region: data.sub_region || '',
                fact_population: data.fact_population || '',
                is_show_in_popup: data.is_show_in_popup || false,
              }, { http: axios })
              this.is_playing_quizmaker = this.form.is_playing_quizmaker
              this.loaded = true
            })
        } else this.loaded = true
      })
  },
  methods: {
    save () {
      let url = this.cityId !== undefined ? `/api/cities/${this.cityId}` : `/api/cities`
      let method = this.cityId !== undefined ? 'patch' : 'post'
      this.form[method](url).then(
        () => {
          this.$emit('created')
          this.modalHolder.close()
        }
      )
    },
    applySearchFilter (search, thems) {
      let temp_arr = []
      thems.forEach((item) => {
        if (((item.name).toLowerCase()).indexOf(search.toLowerCase()) > -1) temp_arr.push((item))
      })
      return temp_arr
    },
    cancel () {
      this.modalHolder.close()
    },
    denySymbols () {
      let evt = window.event
      let regex = new RegExp('^[0-9,-]+$')
      let key = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode)
      if (!regex.test(key)) {
        evt.preventDefault()
        return false
      }
    },
    addNewPack () {
      this.form.pack_numbers.push({
        'game_type_id': null,
        'ids': null,
      })
    },
    changePackNumber (key, params) {
      this.$set(this.form.pack_numbers[key], 'game_type_id', params.game_type_id)
      this.$set(this.form.pack_numbers[key], 'ids', params.ids)
    },
  },
  computed: {
    defaultCountry () {
      return this.countries.filter((item) => {
        return item.name === 'Россия'
      })[0]
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;
</style>
