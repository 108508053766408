<template>
    <div class="checkbox-container">
        <input class="" :checked="value" type="checkbox" :id="`ch-${elementId}`"/>
        <label class="checkmark" @click="changeState"></label>
    </div>
</template>

<script>
export default {
  name: 'CityCheckbox',
  props: ['value', 'elementId'],
  data () {
    return {
      is_checked: this.value,
    }
  },
  watch: {
    is_checked: {
      handler (val) {
        this.$emit('input', val)
      },
      deep: true,
    },
  },
  methods: {
    changeState () {
      this.is_checked = !this.is_checked
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;

    /* The container */
    .checkbox-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 17px;
        width: 17px;
        background-color: #b8c2cc;
    }

    /* On mouse-over, add a grey background color */
    .checkbox-container:hover input ~ .checkmark {
        background-color: #8795a1;
    }

    /* When the checkbox is checked, add a blue background */
    .checkbox-container input:checked ~ .checkmark {
        background-color: #6a6384;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .checkbox-container input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkbox-container .checkmark:after {
        left: 6px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
</style>
