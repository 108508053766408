<template>
    <div class="pagination my-4">
        <a href="#" v-for="link in links" :class="link.class"
           @click.prevent="switchPage(link)">
            {{link.text}}
        </a>
    </div>
</template>

<script>
export default {
  name: 'PaginationLinks',
  inject: ['paginationState'],
  watch: {
    'paginationState.meta.last_page': {
      handler (val, oldval) {
        if (oldval === this.paginationState.meta.current_page) {
          this.$emit('page', val)
        }
      },
    },
  },
  methods: {
    switchPage (link) {
      if (!link.class.disabled) {
        this.$emit('page', link.page)
      }
    },
  },
  computed: {
    meta () {
      return this.paginationState.meta
    },

    links () {
      let links = []

      links.push({
        'page': this.meta.current_page - 1,
        'text': '<',
        'class': { 'disabled': this.meta.current_page == 1 },
      })

      links.push({
        'page': 1,
        'text': 1,
        'class': { 'active': this.meta.current_page == 1 },
      })

      if (this.meta.current_page > 3) {
        links.push({
          'page': null,
          'text': '...',
          'class': { 'disabled': true },
        })
      }

      for (let i = Math.max(2, this.meta.current_page - 1); i <= Math.min(this.meta.last_page - 1, this.meta.current_page + 1); i++) {
        links.push({
          'page': i,
          'text': i,
          'class': { 'active': this.meta.current_page == i },
        })
      }

      if (this.meta.current_page + 2 < this.meta.last_page) {
        links.push({
          'page': null,
          'text': '...',
          'class': { 'disabled': true },
        })
      }

      if (this.meta.last_page > 1) {
        links.push({
          'page': this.meta.last_page,
          'text': this.meta.last_page,
          'class': { 'active': this.meta.current_page == this.meta.last_page },
        })
      }

      links.push({
        'page': this.meta.current_page + 1,
        'text': '>',
        'class': { 'disabled': this.meta.current_page == this.meta.last_page },
      })

      return links
    },
  },
}

</script>
