<template>
  <div class="container mx-auto flex flex-col px-8 mx-4">
    <partner-filter class="-mb-9 z-10"></partner-filter>
    <div class="container mx-auto flex justify-between">
      <smart-filter :main-url="'/api/partners/all?sort=name,-id&'" :route="$route">
        <div class="w-full" slot-scope="{ link, values }">
          <pagination :base-url="link">
            <div
              class="w-full px-4"
              slot-scope="{ data: partners, processing, switchPage, fetchData }"
            >
              <div class="flex items-center">
                <div class="px-4 py-2"></div>
                <button
                  @click.prevent="createPartner"
                  class="
                    flex
                    ml-auto
                    text-white
                    no-underline
                    px-4
                    py-2
                    bg-brand
                    rounded
                    hover:bg-brand-light
                    whitespace-no-wrap
                  "
                >
                  Добавить партнера
                </button>
              </div>
              <div class="w-full" v-if="!processing">
                <div class="flex w-full justify-between items-baseline mb-8">
                  <div></div>
                  <item-count :title="'партнеров'"></item-count>
                </div>
                <div
                  class="
                    rounded
                    border-grey
                    shadow-md
                    text-left
                    w-full
                  "
                >
                  <table class="p-4 tableSize">
                    <thead>
                      <tr>
                        <th class="sizeMin"></th>
                        <th>Партнеры</th>
                        <th>Почта</th>
                        <th class="citySize">Город</th>
                        <th class="citySize">Доступные продукты</th>
                        <th class="citySize">Статус</th>
                        <th style="width: 60px">Статус блок</th>
                        <th >Комментарий</th>
                      </tr>
                    </thead>
                    <tbody>
                      <partners
                        v-for="(partner, key) in partners"
                        :value="partner"
                        :key="key"
                        :partner="partner"
                      ></partners>
                    </tbody>
                  </table>
                </div>
                <pagination-links @page="switchPage"></pagination-links>
              </div>
              <spinner v-else></spinner>
              <portal to="create-new-partner">
                <partner-create class="" @created="fetchData"></partner-create>
              </portal>
            </div>
          </pagination>
        </div>
      </smart-filter>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/pagination.vue'
import PaginationLinks from '../../components/pagination-links.vue'
import Trash from '../../icons/trash'
import ItemCount from '../../components/itemCount'
import Partners from './partners'
import Spinner from '../../components/spinner'
import SmartFilter from '../../components/questions/filter.vue'
import PartnerFilter from './filter-panel'
import PartnerCreate from './create'

export default {
  components: {
    PartnerFilter,
    PartnerCreate,
    Spinner,
    Partners,
    ItemCount,
    Trash,
    PaginationLinks,
    Pagination,
    SmartFilter,
  },
  data () {
    return {
      selectedCities: [],
      checked: false,
      searchText: '',
    }
  },
  methods: {
    createPartner () {
      this.modalHolder.open(
        'create-new-partner',
        'Добавить партнера',
        'city-modal'
      )
    },
  },
}
</script>

<style scoped>
@tailwind utilities;

th {
  @apply text-left
        p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
}

/* >>> th:first-child {
  border-right: 0 !important;
} */

.citySize {
  width: 135px !important;
}
.sizeMin {
  width: 40px !important;
}
.tableSize {
  width: 100%;
}

.search {
  width: 250px;
}
@media (max-width: 1000px) {
  .tableSize {
    min-width: 930px;
  }
}
</style>
