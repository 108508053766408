<template>
  <div>
    <label class="block text-grey-darker text-sm font-bold mb-2" for="comment">
      Город партнера и доступные продукты
    </label>
    <select-city :form="form" :idx="idx" />
    <!-- <select
      class="
        shadow
        appearance-none
        border border-grey
        w-full
        py-2
        px-3
        text-grey-darker
      "
      v-model="form.cities[idx].id"
    >
      <option
        v-for="city in cities"
        v-text="city.name"
        :value="city.id"
        :key="city.id"
        class="bg-brand rounded p-2 text-white hover:bg-brand-dark"
      ></option>
    </select> -->
    <div class="p-2 mb-4">
      <div
        class="checkbox-container flex items-center"
        v-for="prod in products"
        :key="prod.name"
      >
        <input
          class=""
          type="checkbox"
          :checked="prod.checked"
          @click="checkProducts(prod)"
        />
        <label class="checkmark ml-2">{{ prod.name }}</label>
        <p></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MultiSelect from './MultiSelect'
import CityCheckbox from './checkbox'
import SearchSelect from '../../components/SearchSelect.vue'
import PackAndCategory from './PackAndCategory'
import SelectCity from './SelectCity.vue'

export default {
  name: 'ArrElement',
  props: ['it', 'form', 'idx'],
  components: {
    PackAndCategory,
    CityCheckbox,
    MultiSelect,
    SearchSelect,
    SelectCity,
  },
  data () {
    return {
      cities: [],
      products: [],
      choosenProducts: [],
      choosenCity: null,
    }
  },
  created () {
    // axios.get("/api/cities/all").then(({ data: response }) => {
    //   this.cities = response.data;
    //   console.log("cities!!!!", response.data);
    // });

    axios.get('/api/products').then(({ data: response }) => {
      this.products = response.data
      this.products = this.products.map((item) => {
        return {
          id: item.id,
          name: item.name,
          checked: false,
        }
      })
    })
  },
  methods: {
    checkProducts (prod) {
      console.log(this.form.cities, this.form.cities[this.idx].id, 'it eval')
      prod.checked = !prod.checked
      this.checkedProducts = this.products
        .filter((item) => item.checked)
        .map((it) => {
          return { id: it.id }
        })

      console.log('LALALALAL;', this.checkedProducts, this.choosenCity)
    },
  },
  computed: {
    defaultCountry () {
      return this.countries.filter((item) => {
        return item.name === 'Россия'
      })[0]
    },

    generateKey () {
      return `_${Math.random().toString(36).substr(2, 9)}`
    },
  },
}
</script>

<style scoped>
@tailwind utilities;
</style>
