<template>
  <div>
    <select
      class="
        shadow
        appearance-none
        border border-grey
        w-full
        py-2
        px-3
        text-grey-darker
      "
      v-model="formTest.cities[idxTest].id"
    >
      <option
        v-for="city in cities"
        v-text="city.name"
        :value="city.id"
        :key="city.id"
        class="bg-brand rounded p-2 text-white hover:bg-brand-dark"
      ></option>
    </select>
  </div>
</template>

<script>
import axios from 'axios'
import MultiSelect from './MultiSelect'
import CityCheckbox from './checkbox'
import SearchSelect from '../../components/SearchSelect.vue'
import PackAndCategory from './PackAndCategory'

export default {
  name: 'SelectCity',
  props: ['form', 'idx'],
  components: { PackAndCategory, CityCheckbox, MultiSelect, SearchSelect },
  data () {
    return {
      cities: [],
      test: null,
      formTest: this.form,
      idxTest: this.idx,
    }
  },
  created () {
    axios.get('/api/cities/all').then(({ data: response }) => {
      this.cities = response.data
      console.log('cities!!!!', response.data)
    })
    console.log('form test', this.formTest)
    // this.cities ? (this.localOptions = this.cities) : "";
    // axios.get("/api/cities/all").then(({ data: response }) => {
    //   this.cities = response.data;
    //   console.log("cities!!!!", response.data);
    // });
    // axios.get("/api/products").then(({ data: response }) => {
    //   this.products = response.data;
    //   this.products = this.products.map((item) => {
    //     return {
    //       id: item.id,
    //       name: item.name,
    //       checked: false,
    //     };
    //   });
    //   console.log("products!!!!", this.products);
    // });
    // console.log(it, "lala!!!");
  },
  methods: {
    // checkProducts(prod) {
    //   console.log(this.form.cities, this.form.cities[this.idx].id, "it eval");
    //   prod.checked = !prod.checked;
    //   this.checkedProducts = this.products
    //     .filter((item) => item.checked)
    //     .map((it) => {
    //       return { id: it.id };
    //     });
    //   console.log("LALALALAL;", this.checkedProducts, this.choosenCity);
    // },
  },
  computed: {
    // cities() {
    //   return axios.get("/api/cities/all").then(({ data: response }) => {
    //     return response.data;
    //   });
    // },
    // defaultCountry() {
    //   return this.countries.filter((item) => {
    //     return item.name === "Россия";
    //   })[0];
    // },
    // generateKey() {
    //   return `_${Math.random().toString(36).substr(2, 9)}`;
    // },
  },
}
</script>

<style scoped>
@tailwind utilities;
</style>
