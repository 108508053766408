<template>
    <on-click-outside :do="close">
        <div class="search-select" :class="{ 'is-active': isOpen }">
            <button ref="button" @click="open" type="button" class="search-select-input">
                <span>{{ currentState }}</span>
            </button>
            <div ref="dropdown" v-show="isOpen" class="search-select-dropdown">
                <ul ref="options" class="search-select-options">

                    <template v-for="option in options">
                        <li class="search-select-option"
                            @click="changeState(option)"
                            v-if="option.type !== 'delimeter'"
                            :key="option.id"
                            :class="{ 'is-active': contains(option)}"
                        >{{ option.name }}</li>
                        <div class="w-full border-b border border-white" v-else></div>
                    </template>
                </ul>
            </div>
        </div>
    </on-click-outside>
</template>

<script>
import Popper from 'popper.js'
import OnClickOutside from '../../components/OnClickOutside.vue'

export default {
  Name: 'MultiSelect',
  components: {
    OnClickOutside,
  },
  props: ['value', 'options'],
  data () {
    return {
      isOpen: false,
      selected: [],
    }
  },
  created () {
    this.selected = this.value
  },
  computed: {
    currentState () {
      return `${this.selected.length} элементов выбрано`
    },
  },
  beforeDestroy () {
    this.popper.destroy()
  },
  methods: {
    changeState (option) {
      if (this.contains(option)) {
        this.revoke(option)
      } else this.select(option)
    },
    select (option) {
      this.selected.push(option)
    },
    revoke (option) {
      this.selected = this.selected.filter(obj => obj.id !== option.id)
    },
    contains (option) {
      return this.selected.filter(e => e.id === option.id).length > 0
    },
    open () {
      if (this.isOpen) {
        return
      }
      this.isOpen = true
      this.$nextTick(() => {
        this.setupPopper()
      })
    },
    setupPopper () {
      if (this.popper === undefined) {
        this.popper = new Popper(this.$refs.button, this.$refs.dropdown, {
          placement: 'bottom',
        })
      } else {
        this.popper.scheduleUpdate()
      }
    },
    close () {
      if (!this.isOpen) {
        return
      }
      this.$emit('input', this.selected)
      this.isOpen = false
    },
  },
}
</script>

<style scoped>
    .search-select {
        position: relative;
    }
    .search-select-input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-align: left;
        display: block;
        width: 100%;
        border-width: 1px;
        padding: 0.5rem 0.75rem;
        background-color: #fff;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-color: #b4b0c1;
    }
    .search-select-input:focus {
        outline: 0;
        -webkit-box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
        box-shadow: 0 0 0 1px rgba(52, 144, 220, 0.5);
    }
    .search-select-placeholder {
        color: #8795a1;
    }
    .search-select.is-active .search-select-input {
        -webkit-box-shadow: 0 0 0 3px rgba(52, 144, 220, 0.5);
        box-shadow: 0 0 0 1px rgba(52, 144, 220, 0.5);
    }
    .search-select-dropdown {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        position: absolute;
        right: 0;
        left: 0;
        background-color: #6a6384;
        padding: 0.5rem;
        border-radius: 0.25rem;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        z-index: 50;
    }
    .search-select-search {
        display: block;
        margin-bottom: 0.5rem;
        width: 100%;
        padding: 0.5rem 0.75rem;
        background-color: #544e68;
        color: #fff;
        border-radius: 0.25rem;
    }
    .search-select-search:focus {
        outline: 0;
    }
    .search-select-options {
        list-style: none;
        padding: 0;
        position: relative;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        max-height: 14rem;
    }
    .search-select-option {
        padding: 0.5rem 0.75rem;
        color: #fff;
        cursor: pointer;
        border-radius: 0.25rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .search-select-option:hover {
        background-color: #544e68;
    }
    .search-select-option.is-active,
    .search-select-option.is-active:hover {
        background-color: #3490dc;
    }
    .search-select-empty {
        padding: 0.5rem 0.75rem;
        color: #b8c2cc;
    }
</style>
